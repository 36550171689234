import React from "react";
import "./MyWork.css";
import favmobile from "./Images/FavFontsMobile.PNG";
import favfonts from "./Images/FavoriteFontsApplication.png";
import housemobile from "./Images/HouseHeroMobile.PNG";
import househero from "./Images/HouseHeroApplication.png";
import Tutormobile from "./Images/TutoringMobile.png";
import TutorMe from "./Images/TutorMeApplication.png";
import WhenMobile from "./Images/WhenRUFreeMobile.PNG";
import WhenRUFree from "./Images/WhenRUFree.png";
import Lunamobile from "./Images/Lunamobile.png";
import LunaDesktop from "./Images/LunaDesktop.png";
import device from "./Images/LandingPageScreenshot.PNG";
import zodailyWeb from "./Images/zodailyWeb.png";
import zodailyMobile from "./Images/zodailyMobile.png";
import katiuskabeads from "./Images/katiuskabeads.png";
import katiuskabeadsmobile from "./Images/katiuskabeadsmobile.png";
import BBM5klaptop from "./Images/BBM5klaptop.png";
import BBM5kmobile from "./Images/BBM5kmobile.png";

function Projects() {
  return (
    <div className="projects-section">  
      <div className="project-title">
        <h1>RECENT PROJECTS</h1>
      </div>
      <div className="recent-projects">
      <div className="BBM">
        <div className="images">
          <img className="laptop" src={BBM5klaptop}></img>
          <img className="mobile" src={BBM5kmobile}></img>
        </div>
        <div className="caption">
          <h3>RUN LIKE A RHINO 5K</h3>
          <h4>LAUNCHED JUNE 2022</h4>
          <p>
          Run Like A Rhino 5K website is a responsive fundraising website for non-profit Border Buddies Ministries. Features include a Google form with Payable a integration and a dynamic contact form.
          </p>
          <a className="live"href="www.borderbuddies5k.com">LIVE LINK</a>
          <a className="view" href="https://github.com/ach0319/run">VIEW CODE</a>
        </div>
      </div>
      <div className="luna">
        <div className="images">
          <img className="laptop" src={LunaDesktop}></img>
          <img className="mobile" src={Lunamobile}></img>
        </div>
        <div className="caption">
          <h3>LUNA PERFORMANCE</h3>
          <h4>LAUNCHED SEPT 2021</h4>
          <p>
          Luna Performance is a dynamic responsive website for a detailing business. Some features include an active e-commerce shop with a PayPal Integration, contact form, Instagram and testimonials carousel.
          </p>
          <a className="live" href="https://keen-hoover-311d56.netlify.app//">LIVE LINK</a>
          <a className="view" href="https://github.com/ach0319/Luna-Performance">VIEW CODE</a>
        </div>
      </div>

      <div className="katiuska">
        <div className="images">
          <img className="laptop" src={katiuskabeads}></img>
          <img className="mobile" src={katiuskabeadsmobile}></img>
        </div>
        <div className="caption">
          <h3>KB & TEES</h3>
          <h4>LAUNCHED JUNE 2022</h4>
          <p>
          KB & Tees is a dynamic responsive shop for unique beaded jewelry business. Features include an active e-commerce shop with Stripe integration and contact form.
          </p>
          <a className="live" href="https://super-cool-site-by-ach0319.netlify.app">LIVE LINK</a>
          <a className="view" href="https://github.com/ach0319/katiuska-beads">VIEW CODE</a>
        </div>
      </div>
      </div>
      <div className="project-title">
        <h1>OTHER COLLABORATIONS</h1>
      </div>
      <div className="collaborations">
      <div className="zodaily">
        <div className="images">
          <img className="laptop" src={zodailyWeb}></img>
          <img className="mobile" src={zodailyMobile}></img>
        </div>
        <div className="caption">
          <h3>ZODAILY</h3>
          <a className="live" href="https://chingu-voyages.github.io/v35-geckos-team-07/">LIVE LINK</a>
          <a className="view" href="https://github.com/chingu-voyages/v35-geckos-team-07">VIEW CODE</a>
        </div>
      </div>
    
      <div className="device-loaner">
        <div className="images">
          <img className="laptop" src={device}></img>
        </div>
        <div className="caption">
          <h3>Device-Loaner</h3>
          
          <a className="live" href="https://jovial-mcclintock-f98a35.netlify.app/">LIVE LINK</a>
          <a className="view" href="https://github.com/chingu-voyages/v33-geckos-team-05">
            VIEW CODE
          </a>
        </div>
      </div>
      
      <div className="When-RU-Free">
        <div className="images">
          <img className="laptop" src={WhenRUFree}></img>
          <img className="mobile" src={WhenMobile}></img>
        </div>
        <div className="caption">
          <h3>When RU Free </h3>
          
          <a className="live" href="https://chingu-voyages.github.io/WhenRUfree/">LIVE LINK</a>
          <a className="view" href="https://github.com/chingu-voyages/WhenRUfree">VIEW CODE</a>
        </div>
      </div>
      </div>
      <div className="collaborations">
      <div className="tutor-me">
        <div className="images">
          <img className="laptop" src={TutorMe}></img>
          <img className="mobile" src={Tutormobile}></img>
        </div>
        <div className="caption">
          <h3>Tutor Me </h3>
          
          <a className="live" href="https://toucans01tutorme.netlify.app/">LIVE LINK</a>
          <a className="view" href="https://github.com/chingu-voyages/v29-toucans-team-01">
            VIEW CODE
          </a>
        </div>
      </div>
      
      <div className="house-hero">
        <div className="images">
          <img className="laptop" src={househero}></img>
          <img className="mobile" src={housemobile}></img>
        </div>
        <div className="caption">
          <h3>House Hero</h3>
          
          <a className="live" href="https://loving-shannon-21b085.netlify.app/">LIVE LINK</a>
          <a className="view" href="https://github.com/chingu-voyages/v26-toucans-team-01">
            VIEW CODE
          </a>
        </div>
      </div>
      
      <div className="fav-fonts">
        <div className="images">
          <img className="laptop" src={favfonts}></img>
          <img className="mobile" src={favmobile}></img>
        </div>
        <div className="caption">
          <h3>Favorite Fonts</h3>
          
          <a className="live" href="https://favorite-fonts-application.herokuapp.com/">
            LIVE LINK
          </a>
          <a className="view" href="https://github.com/ach0319/Chingu-Favorite-Fonts-App">
            VIEW CODE
          </a>
        </div>
        </div>
      </div>
      
    </div>
  );
}

export default Projects;
