import React from "react";
import NavBar from "./Components/Landing Page/NavBar/NavBar";
import About from "./Components/Landing Page/About/About";
import Projects from "./Components/Landing Page/MyWork/MyWork";
import Contact from "./Components/Landing Page/Contact/Contact";
import "./App.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTwitter} from '@fortawesome/free-brands-svg-icons'
import {faLinkedin} from '@fortawesome/free-brands-svg-icons'
import {faGithub} from '@fortawesome/free-brands-svg-icons'
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";


function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <Routes>
          <Route path="/" exact element={<LandingPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
}

const LandingPage = () => (
  <div className="landing-app">
    <div className="typewriter">
      <p className="type_1">Hi, I'm Ashley Christie.</p>
      <p className="type_2">I am a Front-End</p>
      <p className="type_3">Developer.</p>
      <Link to={"/projects"} style={{backgroundColor:'#313340',
  paddingTop: '1rem',
  paddingLeft: '2rem',
  paddingRight: '2rem',
  paddingBottom: '1rem',
  marginTop: '1rem',
  color: '#FD134D',
  textDecoration: 'none',
  marginRight: 'auto',
  marginLeft: 'auto', }}>Start Here</Link>
    </div>
    <div className="icon-bar">
      <a href="https://twitter.com/ach0319" className="twitter"><FontAwesomeIcon icon={faTwitter} /></a>
      <a href="https://www.linkedin.com/in/ashley-m-christie/" className="linkedin"><FontAwesomeIcon icon={faLinkedin} /></a>
      <a href="https://github.com/ach0319" className="github"><FontAwesomeIcon icon={faGithub} /></a> 
       
    </div>
    <div class="elfsight-app-a8a4d8c8-c53d-4d9b-a148-56ad73125f36"></div>
  </div>
);

export default App;
