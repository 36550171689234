import React from "react";
import "./About.css";
import profile from "./images/profile-picture.jpeg";

function About() {
  return (
    <div className="about-page">
      
      <div className="about-me">
        <p>
        Over the last 2 years, I have pivoted careers and developed the skills to create front-end applications on a team and individually. 
        I am a Front-End Developer with experience developing web applications with HTML, CSS, Bootstrap, JavaScript and React.JS. 
        Previously, I worked 5 years in social services as a licensed social worker.<br></br><br></br>
        I enjoy the process of solving difficult problems and trying to figure out what can make a product better and enhance the customer/user's experience.
         I pride myself in being professional with the ability empathize with what customers need and meeting those needs.<br></br><br></br>
        I am also interested in getting exposure to the back end. I welcome challenges to level up my skills. 
          Beyond the company of my computer, I love to read books, write short
          stories and spend time outdoors<br></br><br></br>
          <b>I want to continue to build my skills as a web developer and would love to help execute your project.</b>
        
        </p>
      </div>
      <div className="profile-picture">
        <img  alt="african american woman face with green nature background" src={profile}></img>
      </div>
      
    </div>
  );
}

export default About;
